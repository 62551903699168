import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

import Container from '../../layouts/container';
import ProjectBreadcrumbs from '../../components/project-breadcrumbs';
import HorizontalRule from '../../components/horizontal-rule';
import Testimonial from '../../components/testimonial';

import { formatDateWithMonth } from '../../utils';

import styles from './projects.module.scss';

// NOTA BENE: Do better than this in future...
const PAGE = 2;

const PageProject = ({ data }) => {
  const { title, date, thumbnail } = data.pagesJson.portfolio.projects[PAGE];

  return (
    <Container>
      <ProjectBreadcrumbs>{title}</ProjectBreadcrumbs>
      <p className={styles.date}>{formatDateWithMonth(new Date(date))}</p>
      <h1 className={styles.projectTitle}>{title}</h1>

      <section className={styles.headerWithImage}>
        <div className={styles.headerContent}>
          <p className={classNames(styles.paragraph, styles.summary)}>Redburn’s research website hosts all content produced by the company’s research analysts. Two years after launch, and with a 50% increase in hosted content, it had become increasingly obvious that the homepage was no longer offering clients a clear view of the content available. I was tasked with leading a project to design a new format to include several improvements to user experience, with a focus on offering a clearer and more condensed product showcase, and improved page loading speeds.</p>
          <Testimonial attribution="Senior Partner, Redburn">I like the look and feel of the new product. Looks seriously high spec, but also identifiably Redburn and different. Great stuff!</Testimonial>
        </div>
        <Img className={styles.headerImage} fluid={data.headerImage.childImageSharp.fluid} />
      </section>

      <HorizontalRule />

      <section className={styles.projectBody}>
        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Role</h2>
          <p className={styles.paragraph}>I led the UX design of the new homepage, working in collaboration with our Sales team to define requirements. I was involved in the full process from discovery to delivery over the space of twelve months.</p>
          <p className={styles.paragraph}>The rest of the team encompassed a group of five engineers, a Project Manager/Scrum Master and a number of senior stakeholders from the business development team.</p>
          <p className={classNames(styles.paragraph, styles.italic)}>Please note: to maintain company and client confidentiality, I am unable to publicly release full content. The details shown below are for illustrative purposes only and should not be shared. All views in this case study are my own and do not necessarily reflect those of Redburn.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Problem</h2>
          <p className={styles.paragraph}>Redburn’s research website hosts all content produced by the various research teams — whether research analysts, salespeople or technical/systematic analysts — and is updated live. However, two years post-launch, it had become clear that the site had outgrown its initial design and was no longer doing justice to the large volume of research published daily.</p>
          <p className={styles.paragraph}>Conversations with clients (and with users within the company) increasingly mentioned that the homepage didn’t seem to have the piece of research they were looking for. On investigation, the article usually was there, but if clients were unable to find it, it might as well not have been.</p>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Design</h2>
          <p className={styles.paragraph}>Before starting on design, I undertook substantial user surveys, gathering data from both internal users and clients to establish the specifics of users’ frustrations.</p>
          <p className={styles.paragraph}>The problem could be broken down into three main strands. First, the large cards at the top of the page (curated content) flag leading content successfully, but to the detriment of everything else, especially on small screens. Second, the main carousel of content shows only five articles (and fewer on smaller screens). On mobile, you see only one article before you have to scroll. Third, the large images and videos required for each carousel significantly slow down the page load speed.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerLg)}>
            <Img fluid={data.my_redburn_01.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>The first step was to reduce the size of the cards. I didn’t want to lose the colour scheme as this had proven useful for quickly differentiating content from the various teams, and was consistent across the rest of the site. I also didn’t want to lose the visual impact of the highlighted cards at the top, as this is the content our Sales team is currently highlighting.</p>
          <p className={styles.paragraph}>After brainstorming possible solutions and carrying out some discovery research, I decided to use a tabular format for the main feed and to keep the card layout for the highlighted content, but to move this to a vertical carousel on the right hand side instead.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerLg)}>
            <Img fluid={data.my_redburn_02.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>This new design doubled the number of cards visible on initial page view, but I wanted to be sure that clients would understand the new layout, so I carried out further user testing. I also presented the designs to a team of senior stakeholders. The designs received approval, so I handed them over to our engineering team to develop.</p>
          <p className={styles.paragraph}>During a series of two-week sprints, we iterated on the basic layout, gradually adding in extra features as required. As the various elements were created, I ensured that they matched back to the original designs, except where to do so would not be technically feasible in the timeframe available.</p>
          <p className={styles.paragraph}>As the project progressed, stakeholders continued to suggest additional features. I coordinated the receipt of such features and passed them to our Project Manager to assess whether they would impact on the delivery of the overall project. When approved, they were included within the appropriate sprint.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerLg)}>
            <Img fluid={data.my_redburn_03.childImageSharp.fluid} />
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionHeader}>Outcome</h2>
          <p className={styles.paragraph}>The final design met all the original goals and was optimised for display on both full screen and mobile.</p>

          <div className={classNames(styles.imageContainer, styles.imageContainerLg)}>
            <Img fluid={data.my_redburn_04.childImageSharp.fluid} />
          </div>

          <p className={styles.paragraph}>However, it was decided that full release to all clients would be delayed until the parallel project looking at client preferences was also ready, so that the two sets of new features could be launched simultaneously.</p>
          <p className={styles.paragraph}>The site is now live and client feedback has been initially very positive, with both internal and external users commenting that the content is much easier to view and the page loads significantly faster.</p>
        </section>
      </section>
    </Container>
  );
};

export const imageQuery = graphql`
  fragment imageQuery on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
{
  pagesJson(portfolio: {projects: {elemMatch: {slug: {regex: "/-/"}}}}) {
    portfolio {
      projects {
        slug
        thumbnail
        title
        date
      }
    }
  }
  headerImage: file(relativePath: { eq: "projects/my-redburn.png" }) {
    ...imageQuery
  }
  my_redburn_01: file(relativePath: { eq: "projects/my-redburn-design-01.png" }) {
    ...imageQuery
  }
  my_redburn_02: file(relativePath: { eq: "projects/my-redburn-design-02.png" }) {
    ...imageQuery
  }
  my_redburn_03: file(relativePath: { eq: "projects/my-redburn-design-03.png" }) {
    ...imageQuery
  }
  my_redburn_04: file(relativePath: { eq: "projects/my-redburn-design-04.png" }) {
    ...imageQuery
  }
}
`;

export default PageProject;
